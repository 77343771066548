<template>
  <div style="width: 350px; margin: 0 auto;">
    <vue-cal
      :class="[{ 'hide-previous': hidePrevious, 'hide-next': hideNext }]"
      :time="false"
      :min-date="minDate"
      :max-date="maxDate"
      :events="events"
      :disable-views="['day', 'week', 'year', 'years']"
      default-view="month"
      xsmall
      hide-view-selector
      events-on-month-view
      show-all-day-events
      locale="de"
      @cell-click="select"
      @ready="changeView"
      @view-change="changeView"
    ></vue-cal>
  </div>
</template>

<script>
import VueCal from "vue-cal";
import "vue-cal/dist/vuecal.css";
import "vue-cal/dist/i18n/de";
import format from "date-fns/format";

export default {
  name: "Calendar",

  components: {
    VueCal
  },

  data() {
    return {
      startOfView: new Date(),
      endOfView: new Date(),
      selectedEvent: null,
      bookedEvents: []
    };
  },

  computed: {
    currentMonth() {
      return this.startOfView.getMonth();
    },

    hidePrevious() {
      const minMonth = this.minDate.getMonth();
      return minMonth === this.currentMonth;
    },

    hideNext() {
      const maxMonth = this.maxDate.getMonth();
      return maxMonth === this.currentMonth;
    },

    minDate() {
      return new Date();
    },

    maxDate() {
      const max = new Date();
      const month = max.getMonth();
      max.setMonth(month + 7);
      max.setDate(0);
      return max;
    },

    events() {
      const bookedEvents = this.bookedEvents.map(event => ({
        start: event.start,
        end: event.end,
        title: event.summary,
        class: "booked"
      }));

      return this.selectedEvent
        ? [...bookedEvents, this.selectedEvent]
        : bookedEvents;
    }
  },

  methods: {
    /**
     * @param {Object} payload
     */
    changeView(payload) {
      this.startOfView = payload.startDate;
      this.endOfView = payload.endDate;
    },

    /**
     * @param {Date} date
     * @return {Date}
     */
    normalizeDate(date) {
      date.setHours(12, 0, 0, 0);
      return date;
    },

    /**
     * @param {String} dateString
     * @return {Date}
     */
    toDateObj(dateString) {
      const ds = dateString.split("-");
      const date = new Date(ds[0], ds[1] - 1, ds[2]);
      return this.normalizeDate(date);
    },

    /**
     * @param {Object} event
     * @param {String} date
     * @return {Boolean}
     */
    filterEvents(event, date) {
      const greaterThanStartDate =
        this.toDateObj(event.start) <= this.normalizeDate(date);
      const smallerThanEndDate =
        this.toDateObj(event.end) >= this.normalizeDate(date);

      return greaterThanStartDate && smallerThanEndDate;
    },

    /**
     * @param {Date} date
     * @return {String}
     */
    fromDateToString(date) {
      return format(date, "yyyy-MM-dd");
    },

    /**
     * @param {Date} date
     * @fires event-selected
     */
    select(date) {
      this.selectedEvent = null;

      const dateString = this.fromDateToString(date);

      const foundEventIdx = this.events.findIndex(ev =>
        this.filterEvents(ev, date)
      );

      if (foundEventIdx === -1) {
        this.selectedEvent = {
          start: dateString,
          end: dateString,
          title: "",
          class: "selected"
        };
      }

      this.$root.$emit("event-selected", this.selectedEvent);
    },

    thereIsNotAnotherEventInSameDay(events, startDate) {
      return !events.find(ev => ev.start === startDate);
    }
  },

  async mounted() {
    const fromApi = await fetch("/calendar").then(response => response.json());

    if (fromApi.length) {
      this.bookedEvents = fromApi.reduce((acc, event) => {
        if (this.thereIsNotAnotherEventInSameDay(acc, event.start)) {
          acc.push(event);
        }
        return acc;
      }, []);
    }
  }
};
</script>

<style lang="postcss">
.vuecal {
  box-shadow: none;
}

.vuecal.hide-previous .vuecal__arrow.vuecal__arrow--prev {
  display: none;
}

.vuecal.hide-next .vuecal__arrow.vuecal__arrow--next {
  display: none;
}

.vuecal__title-bar {
  background-color: transparent;
  margin-bottom: 10px;
}

.vuecal__arrow {
  height: 50px;
  width: 50px;
}

.vuecal__title-bar .vuecal__title {
  color: #19191f;
  font-family: "Avenir", sans-serif;
  font-size: 18px;
  font-stretch: normal;
  font-style: normal;
  font-weight: 900;
  letter-spacing: normal;
  line-height: normal;
  text-transform: uppercase;
}

.vuecal__weekdays-headings {
  border-bottom: none;
}

.vuecal__weekdays-headings .vuecal__heading {
  height: 50px;
}

.vuecal__heading .weekday-label {
  color: #000;
  font-family: Avenir;
  font-size: 12px;
  font-weight: 900;
}

.vuecal__cell {
  height: 50px;
}

.vuecal__cell::before {
  border: none;
}

.vuecal__cell:not(.out-of-scope)::before {
  border: solid 2px #f1f1f1;
}

.vuecal__cell.selected {
  background-color: transparent;
}

.vuecal__cell-date {
  font-size: 12px;
  font-weight: 900;
  z-index: -2;
}

.vuecal__cell.out-of-scope .vuecal__cell-content {
  display: none;
}

.vuecal__cell.disabled.before-min {
  color: #e5e5e5;
}

.vuecal__cell.disabled.after-max {
  color: #e5e5e5;
}

.vuecal__cell-events-count {
  display: none;
}

.vuecal__event--focus {
  box-shadow: none;
}

.vuecal__cell-events {
  height: 100%;
  opacity: 0.5;
  position: absolute;
  z-index: -1;
}

.vuecal__event {
  height: 100%;
  z-index: 0;
}

.vuecal__event.booked {
  background-color: #f79892;
}

.vuecal__event.selected {
  background-color: #00fca3;
}

.vuecal__event-title {
  opacity: 0;
}
</style>
